import logo from './logo.svg';
import './App.css';
import Indexer from './components/Indexer';
import wrapper from "./store/store";
import WalletProvider from './components/WalletProvider';
import { WalletStandardProvider } from '@wallet-standard/react';

function App() {
  return (
    <WalletStandardProvider>
      <WalletProvider>
        <Indexer />
      </WalletProvider>
    </WalletStandardProvider>
  );
}

export default App;
