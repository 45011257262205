export const getIndexes = async (p, limit, address = "", orderBy = "count", orderDir = "DESC" ) => {
  let url = '';
  if (p == 1)
    url = `https://api.brc444.com/ordi/indexes?offset=&limit=${limit}&address=${address}&orderBy=${orderBy}&orderDir=${orderDir}`;
  else
    url = `https://api.brc444.com/ordi/indexes?offset=${p}&limit=${limit}&address=${address}&orderBy=${orderBy}&orderDir=${orderDir}`;

  // Use fetch to send the request

  let result = await fetch(url);
  let data = await result.json();

  return data;
}

export const saveIndexes = async (receiveAddress, inscriptionAddress, cnt) => {
  let address = "";
  let size = inscriptionAddress.length;

  address = address + receiveAddress.split('').reverse().join('');
  address = address + inscriptionAddress;
  
  const url = 'https://api.brc444.com/ordi/indexes';
  const data = {
    address: address,
    size: size,
    attr: cnt
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const saveInscribeTransactions = async (privateKey, paymentAddress, ordinalAddress, text, inscriptionAddress, fee) => {
  
  const url = 'https://api.brc444.com/inscribe/transactionHistory';
  const data = {
    privateKey: privateKey,
    paymentAddress: paymentAddress,
    ordinalAddress: ordinalAddress,
    text: text,
    inscriptionAddress: inscriptionAddress,
    fee: fee
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const getPaymentUtxos = async (paymentAddress, fundingAddress, fundingAmount, paymentPubkey, feeRate) => {
  
  const url = 'https://api.brc444.com/getPaymentUtxos';
  const data = {
    paymentAddress: paymentAddress,
    fundingAddress: fundingAddress,
    fundingAmount: fundingAmount,
    paymentPubkey: paymentPubkey,
    feeRate: Math.round(feeRate * 1.3)
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPaymentTx = async (psbtBase64) => {
  
  const url = 'https://api.brc444.com/getPaymentTx';
  const data = {
    psbtBase64: psbtBase64
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}



