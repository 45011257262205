export const saveDataToStorage = (domain, address) => {
  localStorage.setItem('wallet', address);
  localStorage.setItem('domain', domain);
}

export const getDataToStorage = () => {
  return {
    'wallet': localStorage.getItem('wallet'),
    'domain': localStorage.getItem('domain')
  }
}