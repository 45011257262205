import { createStore, applyMiddleware } from 'redux';
import { thunk as thunkMiddleware } from 'redux-thunk';

import rootReducer from './rootReducer';

const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware) // Use the correctly imported middleware here
);

export default store;