import { SET_ADDRESS } from "./action";

export const initialState = { 
  xWalletName : "",
  domain : "",
  nostrOrdinalsAddress : "",
  nostrPaymentAddress : "",
  ordinalsPublicKey : "",
  paymentPublicKey : ""
};
export default function walletReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ADDRESS:
      return { ...action.data };
    default:
      return state;
  }
}